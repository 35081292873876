import React from 'react'
import { toast } from 'react-toastify'
import useSWRImmutable from 'swr/immutable'

import { appActions } from '@features/app/reducers'
import useJobListingQuery from '@features/jobs/hooks/useJobListingQuery'
import { useJobListingStateQueries } from '@features/jobs/job-detail/reducers/selectors'
import { updateJobDetail } from '@features/jobs/job-detail/reducers/thunk-actions'
import { userKycInReview, userKycRejected } from '@features/kyc/helpers'
import {
  GetEmployerProfileAPIResponseData,
  KycVerificationStatus,
} from '@features/kyc/types'

import QuestionModal from '@components/modal/question-modal'

import { useAppDispatch, useAppSelector } from '@hooks/redux'

import {
  JOB_SEARCH_ACTIVATE_JOB_LISTING,
  JOB_SEARCH_DEACTIVATE_JOB_LISTING,
} from '@constants/event-tracking'
import { JobStatus } from '@constants/job-statuses'
import MODAL_NAME from '@constants/modal-name'
import { EMPLOYERS_PROFILE_URL } from '@constants/url'

import errorFormat from '@utils/error-format'
import trackEvents from '@utils/track-events'

const ChangeJobStatusQuestionModal = () => {
  const modalName = useAppSelector((state) => state.appReducer.modalName)

  const modalId = useAppSelector((state) => state.jobReducer.modalId)

  const dispatch = useAppDispatch()

  const { data: employerProfileData } =
    useSWRImmutable<GetEmployerProfileAPIResponseData>(EMPLOYERS_PROFILE_URL)
  const companyId = employerProfileData?.company?.id || ''

  const jobListingQueries = useJobListingStateQueries()
  const { mutate } = useJobListingQuery({
    companyId,
    query: jobListingQueries,
  })

  const changeJobStatus = async (jobId: string, currentStatus: JobStatus) => {
    if (!employerProfileData) return
    if (
      userKycRejected({
        employerKyc: employerProfileData?.kycStatus as KycVerificationStatus,
        companyKyc: employerProfileData.company
          ?.kycStatus as KycVerificationStatus,
      })
    ) {
      dispatch(appActions.setModalName(MODAL_NAME.KYC_REVERIFY_REJECTED_MODAL))
      return
    }

    if (
      userKycInReview({
        employerKyc: employerProfileData?.kycStatus as KycVerificationStatus,
        companyKyc: employerProfileData?.company
          ?.kycStatus as KycVerificationStatus,
      })
    ) {
      dispatch(appActions.setModalName(MODAL_NAME.KYC_INREVIEW_NOTIFY_MODAL))
      return
    }

    const isJobActive = currentStatus === 'Active'

    try {
      await dispatch(
        updateJobDetail({
          jobId,
          isActive: !isJobActive,
        })
      ).unwrap()

      if (isJobActive) {
        trackEvents(JOB_SEARCH_DEACTIVATE_JOB_LISTING)
      } else {
        trackEvents(JOB_SEARCH_ACTIVATE_JOB_LISTING)
      }
      toast.success(
        `${isJobActive ? 'Loker berhasil di non-aktifkan!' : 'Loker berhasil diaktifkan!'}`,
        {
          hideProgressBar: true,
          style: {
            backgroundColor: '#52B788',
            color: '#FFFFFF',
          },
        }
      )
      mutate()
      dispatch(appActions.removeModal())
    } catch (changeJobError) {
      toast.error(errorFormat(changeJobError))
    }
  }
  return (
    <QuestionModal
      open={modalName === MODAL_NAME.QUESTION_MODAL}
      onClose={() => dispatch(appActions.removeModal())}
      onSubmit={() => changeJobStatus(modalId, 'Active')}
    />
  )
}

export default ChangeJobStatusQuestionModal
