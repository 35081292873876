import classNames from 'classnames'
import React from 'react'

import { useJobsActions } from '@features/jobs/job-detail/reducers'

import { useAppSelector } from '@hooks/redux'

import { MAP_JOB_ORDER } from '@constants/job-order'

import { CheckmarkIcon } from '@common/icons'

type JobListingOrder = keyof typeof MAP_JOB_ORDER

const JobListingSort = () => {
  const [open, setOpen] = React.useState(false)

  const jobListingOrder = useAppSelector(
    (state) => state.jobReducer.jobListingQueries.order
  )

  const jobsActions = useJobsActions()

  return (
    <div className="relative">
      <button
        type="button"
        onClick={() => {
          setOpen((s) => !s)
        }}
        className={classNames(
          'inline-flex',
          'items-center',
          'px-3',
          'py-2',
          'text-base',
          'border border-neutral-gray-100',
          'rounded-[6px]',
          'text-primary-gray',
          'h-full',
          'hover:border-primary-blue',
          open
            ? 'before:content-[" "] before:fixed before:inset-0 before:z-[39] before:block before:cursor-default before:bg-transparent border-primary-blue'
            : ''
        )}
      >
        <div className="flex items-center gap-x-[8px]">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.1538 5.04348H1.84607C1.56573 5.04348 1.33325 4.80696 1.33325 4.52174C1.33325 4.23652 1.56573 4 1.84607 4H14.1538C14.4341 4 14.6666 4.23652 14.6666 4.52174C14.6666 4.80696 14.4341 5.04348 14.1538 5.04348Z"
              fill="#717171"
            />
            <path
              d="M12.1025 8.52169H3.89734C3.617 8.52169 3.38452 8.28517 3.38452 7.99995C3.38452 7.71473 3.617 7.47821 3.89734 7.47821H12.1025C12.3828 7.47821 12.6153 7.71473 12.6153 7.99995C12.6153 8.28517 12.3828 8.52169 12.1025 8.52169Z"
              fill="#717171"
            />
            <path
              d="M9.36749 12H6.63245C6.35211 12 6.11963 11.7635 6.11963 11.4783C6.11963 11.1931 6.35211 10.9565 6.63245 10.9565H9.36749C9.64783 10.9565 9.88031 11.1931 9.88031 11.4783C9.88031 11.7635 9.64783 12 9.36749 12Z"
              fill="#717171"
            />
          </svg>
          <span>{MAP_JOB_ORDER[jobListingOrder]}</span>
        </div>
      </button>
      <div
        className={classNames(
          'absolute',
          'left-0',
          'md:left-[initial]',
          'z-[40]',
          'bg-white',
          'rounded-md',
          'shadow-elevation-16',
          'mt-[10px]',
          'w-[224px]',
          'max-h-[600px]',
          'overflow-auto',
          'w-[224p]x',
          'right-0',
          'shadow-elevation-8',
          'border-[1px]',
          'border-neutral-gray-100',
          !open ? 'hidden' : 'block'
        )}
      >
        {Object.keys(MAP_JOB_ORDER).map((key) => (
          <JobListingOrderOptionButton
            key={key}
            label={MAP_JOB_ORDER[key as JobListingOrder]}
            isChecked={jobListingOrder === key}
            onClickButton={() => {
              jobsActions.setOrder(key as JobListingOrder)
              setOpen(false)
            }}
          />
        ))}
      </div>
    </div>
  )
}

const JobListingOrderOptionButton = ({
  onClickButton,
  label,
  isChecked,
}: {
  isChecked: boolean
  label: React.ReactNode
  onClickButton: React.MouseEventHandler<HTMLButtonElement>
}) => {
  return (
    <button
      type="button"
      className={classNames(
        'hover:bg-[#F4F6FB]',
        'w-full',
        'inline-flex',
        'items-center',
        'text-left',
        'mx-auto',
        'transition',
        'duration-150',
        'ease-in-out',
        'focus:outline-none',
        'focus-visible:ring',
        'focus-visible:ring-primary-blue',
        'focus-visible:ring-opacity-50',
        'px-[16px]',
        'py-[12px]',
        'last:rounded-b-[6px]',
        'first:rounded-t-[6px]',
        isChecked && 'bg-[#F4F6FB]'
      )}
      onClick={onClickButton}
    >
      {label}
      {isChecked && <CheckmarkIcon className="ml-auto" />}
    </button>
  )
}

export default JobListingSort
