import { useRouter } from 'next/router'
import React from 'react'
import ContentLoader from 'react-content-loader'
import useSWRImmutable from 'swr/immutable'

import { appActions } from '@features/app/reducers'
import useJobListingQuery from '@features/jobs/hooks/useJobListingQuery'
import { useJobListingStateQueries } from '@features/jobs/job-detail/reducers/selectors'
import CreateJobHero from '@features/jobs/screens/hero/create-job/CreateJobHero'
import ErrorHero from '@features/jobs/screens/hero/error/ErrorHero'
import KycInReviewNotifyModal from '@features/kyc/components/kyc-in-review-notify-modal'
import KycRejectedReverifyModal from '@features/kyc/components/kyc-rejected-reverify-modal'
import { GetEmployerProfileAPIResponseData } from '@features/kyc/types'

import Button from '@components/button'
import QuestionModal from '@components/modal/question-modal'

import { useAppDispatch, useAppSelector } from '@hooks/redux'

import MODAL_NAME from '@constants/modal-name'
import { EMPLOYERS_PROFILE_URL } from '@constants/url'

import { JobPost } from '@custom-types/job-post'

import JobCard from '../job-card/JobCard'

const JobListingsLoader: React.FC = () => (
  <div className="block md:grid md:grid-cols-2 md:gap-4 lg:grid-cols-3 xl:grid-cols-4">
    {[...Array(4)].map((_, i) => (
      <div
        className="flex h-auto w-full items-center justify-center"
        key={i.toLocaleString()}
      >
        <ContentLoader
          width={350}
          height={350}
          viewBox="0 0 350 350"
          backgroundColor="#f0f0f0"
          foregroundColor="#dedede"
        >
          <rect x="0" y="0" rx="10" ry="10" width="350" height="350" />
        </ContentLoader>
      </div>
    ))}
  </div>
)

const JobListing = () => {
  const { data: employerProfileData, isLoading } =
    useSWRImmutable<GetEmployerProfileAPIResponseData>(EMPLOYERS_PROFILE_URL)
  const companyId = employerProfileData?.company?.id || ''

  const jobListingQueries = useJobListingStateQueries()

  const {
    error,
    data,
    size,
    setSize,
    isLoading: loadingJobListing,
  } = useJobListingQuery({
    companyId,
    query: jobListingQueries,
    swrInfiniteConfig: {
      keepPreviousData: true,
      revalidateIfStale: true,
    },
  })

  const records = (data || []).reduce(
    (prev: JobPost[], curr) => [...prev, ...curr.records],
    []
  )

  const { selectedJobId } = useAppSelector((state) => state.applications)
  const { modalName } = useAppSelector((state) => state.appReducer)
  const dispatch = useAppDispatch()
  const router = useRouter()
  const isReachingEnd =
    size === data?.[data?.length - 1].metaData.totalCount ?? 1
  const isLoadingMore =
    isLoading || (size > 0 && data && typeof data[size - 1] === 'undefined')

  const loadMoreButtonText = () => {
    if (isLoadingMore) {
      return 'loading'
    }

    if (isReachingEnd) {
      return 'tidak ada pekerjaan lagi'
    }

    return 'sertakan lebih banyak'
  }

  if (error) {
    return <ErrorHero />
  }

  if (loadingJobListing) {
    return <JobListingsLoader />
  }

  if (data && records.length <= 0) {
    return <CreateJobHero />
  }

  return (
    <>
      <div className="block grid md:gap-4 min-[880px]:grid-cols-2 min-[1250px]:grid-cols-3 xl:gap-6">
        {records.map((job, index) => (
          <JobCard
            key={job.id}
            index={index}
            jobPostData={{
              ...job,
              companyName: employerProfileData?.company?.name || '-',
            }}
          />
        ))}
      </div>
      {employerProfileData?.kycStatus === 'Rejected' ||
      employerProfileData?.company?.kycStatus === 'Rejected' ? (
        <KycRejectedReverifyModal />
      ) : null}
      {employerProfileData?.kycStatus === 'InReview' ||
      employerProfileData?.company?.kycStatus === 'InReview' ? (
        <KycInReviewNotifyModal />
      ) : null}
      {!isReachingEnd && !error && records.length !== 0 && (
        <div className="my-4 flex justify-center">
          <Button
            disabled={isLoadingMore || isReachingEnd}
            appearance="outline-primary"
            type="button"
            onClick={() => setSize(size + 1)}
          >
            {loadMoreButtonText()}
          </Button>
        </div>
      )}
      <QuestionModal
        title={
          <p className="mb-6 text-center px-10">
            Kuota lamaran habis! Silakan upgrade paket untuk mengaktifkan
            kembali loker ini.
          </p>
        }
        buttonTextLeft="Nanti"
        open={modalName === MODAL_NAME.ASK_TO_UPGRADE}
        onClose={() => dispatch(appActions.removeModal())}
        onSubmit={() => {
          router.push(`/jobs/upgrade?jid=${selectedJobId}`)
        }}
        buttonTextRight="Upgrade"
      />
    </>
  )
}

export default JobListing
