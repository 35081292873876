import { useRouter } from 'next/router'
import React from 'react'
import { useDispatch } from 'react-redux'
import useSWR from 'swr'
import useSWRImmutable from 'swr/immutable'

import { appActions } from '@features/app/reducers'
import useJobListingQuery from '@features/jobs/hooks/useJobListingQuery'
import { useJobListingStateQueries } from '@features/jobs/job-detail/reducers/selectors'
import {
  userKycInReview,
  userKycPending,
  userKycRejected,
} from '@features/kyc/helpers'
import { GetEmployerProfileAPIResponseData } from '@features/kyc/types'

import Button from '@components/button'
import H1 from '@components/typography/heading-one/H1'
import TitleSection from '@components/ui/title-section'

import { CLICK_ON_POST_NEW_JOB } from '@constants/event-tracking'
import MODAL_NAME from '@constants/modal-name'
import { PATHS } from '@constants/paths'
import { EMPLOYERS_PROFILE_URL, JOB_AVAILABLE_QUOTA } from '@constants/url'

import trackEvents from '@utils/track-events'

const JobListingTitle = () => {
  const router = useRouter()
  const { data: employerProfileData } =
    useSWRImmutable<GetEmployerProfileAPIResponseData>(EMPLOYERS_PROFILE_URL)
  const dispatch = useDispatch()
  const companyId = employerProfileData?.company?.id || ''

  const { data: { basicFreeJobPosts } = 0 } = useSWR(JOB_AVAILABLE_QUOTA)
  const jobListingQueries = useJobListingStateQueries()
  const { data, error } = useJobListingQuery({
    companyId,
    query: jobListingQueries,
  })

  const records = (data || []).flatMap((v) => v.records)

  const handleCreateJobPostClicked = () => {
    trackEvents(`Job Search: ${CLICK_ON_POST_NEW_JOB}`, {
      category: employerProfileData?.isPhantom ? 'MS' : 'JobProvider',
      employerId: employerProfileData?.id,
    })
    if (!employerProfileData || !employerProfileData.company?.kycStatus) return
    if (
      (userKycPending({
        companyKyc: employerProfileData.company.kycStatus,
        employerKyc: employerProfileData.kycStatus,
      }) ||
        userKycRejected({
          companyKyc: employerProfileData.company.kycStatus,
          employerKyc: employerProfileData.kycStatus,
        })) &&
      records.length > 0
    ) {
      dispatch(appActions.setModalName(MODAL_NAME.KYC_NOTICE_MODAL))
      return
    }
    if (
      userKycInReview({
        companyKyc: employerProfileData.company.kycStatus,
        employerKyc: employerProfileData.kycStatus,
      }) &&
      records.length > 0
    ) {
      dispatch(appActions.setModalName(MODAL_NAME.KYC_INREVIEW_NOTIFY_MODAL))
      return
    }
    router.push(PATHS.CREATE_JOB)
  }

  return (
    <TitleSection className="!py-0">
      <div className="flex items-center">
        <H1>Daftar loker</H1>
        {basicFreeJobPosts > 0 ? (
          employerProfileData &&
          employerProfileData.kycStatus === 'Approved' &&
          employerProfileData.company?.kycStatus === 'Approved' && (
            <div className="ml-4 bg-secondary-blue text-primary-black px-2 py-1">
              Sisa kuota loker gratis: <strong>{basicFreeJobPosts}</strong>
            </div>
          )
        ) : (
          <p className="ml-4 bg-secondary-blue text-primary-black px-2 py-1 rounded-md">
            Kuota loker gratis Anda telah habis
          </p>
        )}
      </div>
      {data && !error && (
        <div className="hidden lg:block">
          <Button
            type="button"
            appearance="primary"
            onClick={handleCreateJobPostClicked}
            className="coachmark-jobs_home-step_1"
          >
            Buat loker baru
          </Button>
        </div>
      )}
    </TitleSection>
  )
}

export default JobListingTitle
