/* eslint-disable @next/next/no-img-element */
import { formatNumbersWithSeparator } from '@helpers/formatter'
import React from 'react'
import { toast } from 'react-toastify'
import { useSWRConfig } from 'swr'
import useSWRImmutable from 'swr/immutable'

import { appActions } from '@features/app/reducers'
import useCoinsBalance from '@features/coins/hooks/useCoinsBalance'
import usePriceList from '@features/coins/hooks/usePriceList'
import useJobListingQuery from '@features/jobs/hooks/useJobListingQuery'
import { jobActions } from '@features/jobs/job-detail/reducers'
import { useJobListingStateQueries } from '@features/jobs/job-detail/reducers/selectors'
import { duplicateExpiredJob } from '@features/jobs/job-detail/reducers/thunk-actions'
import { GetEmployerProfileAPIResponseData } from '@features/kyc/types'

import Button from '@components/button'
import Modal from '@components/modal'

import { useAppDispatch, useAppSelector } from '@hooks/redux'
import useCoinsFeatureToggle from '@hooks/use-coins-feature-toggle'

import {
  COINS_DEDUCTION_SUCCESS,
  REPOSTED_JOB,
} from '@constants/event-tracking'
import MODAL_NAME from '@constants/modal-name'
import { COINS_BALANCE_URL, EMPLOYERS_PROFILE_URL } from '@constants/url'

import errorFormat from '@utils/error-format'
import trackEvents from '@utils/track-events'

import CloseIcon from '@common/icons/CloseIcon'

const ChargeRepostJobModal = () => {
  const [loadingRepost, setLoadingRepost] = React.useState(false)
  const modalName = useAppSelector((state) => state.appReducer.modalName)
  const modalJobId = useAppSelector((state) => state.jobReducer.modalId)

  const { isCoinsFeatureEnabled } = useCoinsFeatureToggle()
  const dispatch = useAppDispatch()

  const { coinsBalanceData } = useCoinsBalance()
  const { priceListData } = usePriceList()

  const { data: employerProfileData } =
    useSWRImmutable<GetEmployerProfileAPIResponseData>(EMPLOYERS_PROFILE_URL)
  const companyId = employerProfileData?.company?.id || ''

  const jobListingQueries = useJobListingStateQueries()

  const { mutate: mutateJobListingQuery } = useJobListingQuery({
    companyId,
    query: jobListingQueries,
  })

  const { mutate } = useSWRConfig()

  const jobRepostPrice =
    priceListData?.find(
      (v) => v.productCode === 'JobPost:Repost' && v.isVisible
    )?.price || 0

  const handleClickConfirmRepostJob = async () => {
    try {
      setLoadingRepost(true)
      await dispatch(duplicateExpiredJob(modalJobId)).unwrap()

      await Promise.all([mutate(COINS_BALANCE_URL), mutateJobListingQuery()])

      setLoadingRepost(false)
      dispatch(appActions.removeModal())

      dispatch(jobActions.changeNewPostHighlight('border-[#fcdf9a]'))
      trackEvents(COINS_DEDUCTION_SUCCESS, {
        product: 'JobPost:Repost',
      })
      trackEvents(REPOSTED_JOB)
      toast('Repost job berhasil', {
        hideProgressBar: true,
        style: {
          backgroundColor: '#52B788',
          color: '#FFFFFF',
        },
      })
    } catch (error) {
      toast.error(errorFormat(error))
    }
  }

  if (!isCoinsFeatureEnabled) return null

  return (
    <Modal
      open={modalName === MODAL_NAME.CHARGE_REPOST_JOB}
      onClose={() => {
        dispatch(appActions.removeModal())
      }}
      isCloseButtonHidden
      isHeaderHidden
      classNamePanel="w-[360px]"
      rootContentClassname="p-[0]"
    >
      <div className="flex items-center justify-between py-[12px] px-[16px]">
        <h1 className="font-semibold text-[18px]">Tayangkan ulang loker</h1>
        <button
          type="button"
          className="text-primary-gray"
          onClick={() => {
            dispatch(appActions.removeModal())
          }}
        >
          <CloseIcon />
        </button>
      </div>

      <div className="p-[16px] flex flex-col gap-[12px]">
        <div className="bg-neutral-gray-25 px-[12px] py-[8px] rounded-[6px]">
          <p className="text-primary-gray text-[12px] font-[400]">
            Semua informasi loker dan kualifikasi yang akan dilihat oleh
            kandidat tetap sama.
          </p>
        </div>
        <div>
          <p className="text-[400] text-[12px] text-primary-gray mb-[4px]">
            Loker yang ingin ditayangkan ulang:
          </p>
          <div className="flex items-center text-primary-blue bg-isrecommended-0 rounded-[6px] w-fit px-[4px] py-[2px]">
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.60009 5.246L6.61509 4.261L5.01009 2.656C4.67009 2.321 4.09009 2.561 4.09009 3.041V8.961C4.09009 9.441 4.67009 9.681 5.01009 9.341L7.60009 6.751C8.01509 6.341 8.01509 5.661 7.60009 5.246Z"
                fill="#4460AC"
              />
            </svg>
            <p className="text-[400] text-[12px]">Branch Manager</p>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between py-[8px] px-[16px] bg-neutral-gray-25">
        <p>Atma Coins yang tersedia</p>
        <div className="flex items-center gap-[4px]">
          <p>
            {coinsBalanceData?.balance
              ? formatNumbersWithSeparator(coinsBalanceData.balance)
              : '-'}
          </p>
          <img
            src="/assets/icons/ic_coin.svg"
            width={16}
            height={16}
            alt="icon"
          />
        </div>
      </div>
      <div className="p-[16px]">
        <Button
          block
          appearance="primary"
          type="button"
          onClick={handleClickConfirmRepostJob}
          isLoading={loadingRepost}
        >
          <div className="flex items-center gap-[4px]">
            <p className="mr-[4px]">Konfirmasi</p>
            <img
              src="/assets/icons/ic_coin.svg"
              width={24}
              height={24}
              alt="icon"
            />
            {jobRepostPrice}
          </div>
        </Button>
      </div>
    </Modal>
  )
}

export default ChargeRepostJobModal
