import { Switch } from '@headlessui/react'
import { get, post } from '@helpers/fetch-wrapper'
import cn from '@helpers/utils'
import { useRouter } from 'next/router'
import React from 'react'
import { toast } from 'react-toastify'
import useSWRImmutable from 'swr/immutable'

import { appActions } from '@features/app/reducers'
import { useApplicationsActions } from '@features/applications/reducers/ApplicationsReducer'
import usePriceList from '@features/coins/hooks/usePriceList'
import Chip from '@features/jobs/components/chip/Chip'
import useJobListingQuery from '@features/jobs/hooks/useJobListingQuery'
import { jobActions, useJobsActions } from '@features/jobs/job-detail/reducers'
import { useJobListingStateQueries } from '@features/jobs/job-detail/reducers/selectors'
import { updateJobDetail } from '@features/jobs/job-detail/reducers/thunk-actions'
import {
  userKycInReview,
  userKycPending,
  userKycRejected,
} from '@features/kyc/helpers'
import {
  GetEmployerProfileAPIResponseData,
  KycVerificationStatus,
} from '@features/kyc/types'

import { useAppDispatch, useAppSelector } from '@hooks/redux'

import {
  BOOST_CLICK_BOOST_BUTTON_JOB_SCREEN,
  CLICK_ON_REPOST_JOB,
  COINS_DEDUCTION_ATTEMPT,
  COINS_DEDUCTION_FAILED,
  COINS_DEDUCTION_INSUFFICIENT_BALANCE,
  JOB_SEARCH_ACTIVATE_JOB_LISTING,
  JOB_SEARCH_CLICK_CARI_KANDIDAT,
  JOB_SEARCH_DEACTIVATE_JOB_LISTING,
} from '@constants/event-tracking'
import { JobStatus } from '@constants/job-statuses'
import MODAL_NAME from '@constants/modal-name'
import { PATHS } from '@constants/paths'
import {
  COINS_BALANCE_URL,
  EMPLOYERS_PROFILE_URL,
  INITIALIZE_URL,
} from '@constants/url'

import { formatDateInID } from '@utils/date-calculation'
import errorFormat from '@utils/error-format'
import trackEvents from '@utils/track-events'

import { MapMarkerIcon } from '@common/icons'

import { useCoinsActions } from '@store/coins/slice'

import JobBadge from '../job-badge'
import JobCardFooter from '../job-card-footer/JobCardFooter'
import JobCardMedia from '../job-card-media/JobCardMedia'
import JobCardMenuToggle from '../job-card-menu-toggle/JobCardMenuToggle'
import JobCardQuota from '../job-card-quota/JobCardQuota'
import { JobListingCardProps } from '../job-listing-card/types'

const JobCard = ({ jobPostData, index }: JobListingCardProps) => {
  const {
    id,
    title,
    requiredGender,
    status,
    expiryAt,
    location,
    minimumAge,
    maximumAge,
    minimumSalary,
    maximumSalary,
    medias,
    productPackage,
    isFree,
    canRepost,
    companyName,
    metric,
  } = jobPostData

  const { data: initializeData } = useSWRImmutable<{
    accessControl: {
      canBoost: boolean
    }
  }>(INITIALIZE_URL, async (url: string) => {
    const response = await post(url)
    return response.data
  })
  const dispatch = useAppDispatch()
  const router = useRouter()
  const { setJobApplicantsPopupFilters } = useApplicationsActions()
  const newPostHighlight = useAppSelector(
    (state) => state.jobReducer.newPostHighlight
  )

  const { data: employerProfileData } =
    useSWRImmutable<GetEmployerProfileAPIResponseData>(EMPLOYERS_PROFILE_URL)
  const companyId = employerProfileData?.company?.id || ''
  const eventProperties = {
    category: employerProfileData?.isPhantom ? 'MS' : 'JobProvider',
    employerId: employerProfileData?.id,
  }
  const { priceListData } = usePriceList()
  const { setProductCode } = useCoinsActions()
  const productCode = 'JobPost:Repost'
  const repostJobPostPrice =
    priceListData?.find(
      (product) => product.productCode === 'JobPost:Repost' && product.isVisible
    )?.price || null

  const jobListingQueries = useJobListingStateQueries()
  const { setSelectJobId } = useApplicationsActions()
  const { mutate } = useJobListingQuery({
    companyId,
    query: jobListingQueries,
  })
  const jobsActions = useJobsActions()

  const changeJobStatus = async (jobId: string, currentStatus: JobStatus) => {
    const isJobActive = currentStatus === 'Active'

    try {
      await dispatch(
        updateJobDetail({
          jobId,
          isActive: !isJobActive,
        })
      ).unwrap()

      if (isJobActive) {
        trackEvents(JOB_SEARCH_DEACTIVATE_JOB_LISTING, eventProperties)
      } else {
        trackEvents(JOB_SEARCH_ACTIVATE_JOB_LISTING, eventProperties)
      }

      toast.success(
        `${isJobActive ? 'Loker berhasil di non-aktifkan!' : 'Loker berhasil diaktifkan!'}`,
        {
          hideProgressBar: true,
          style: {
            backgroundColor: '#52B788',
            color: '#FFFFFF',
          },
        }
      )
      mutate()
      dispatch(appActions.removeModal())
    } catch (changeJobError) {
      toast.error(errorFormat(changeJobError))
    }
  }

  const handleJobStatusChange = () => {
    if (
      status === 'InActive' &&
      productPackage?.passiveApplicantQuota !== -1 &&
      metric?.passiveApplicantCount >= productPackage?.passiveApplicantQuota
    ) {
      setSelectJobId(id)
      dispatch(appActions.setModalName(MODAL_NAME.ASK_TO_UPGRADE))
      return
    }
    if (!employerProfileData || !employerProfileData.company) return
    if (
      userKycPending({
        companyKyc: employerProfileData.company.kycStatus,
        employerKyc: employerProfileData.kycStatus,
      }) ||
      userKycRejected({
        companyKyc: employerProfileData.company.kycStatus,
        employerKyc: employerProfileData.kycStatus,
      })
    ) {
      dispatch(appActions.setModalName(MODAL_NAME.KYC_NOTICE_MODAL))
      return
    }
    if (
      userKycInReview({
        companyKyc: employerProfileData.company.kycStatus,
        employerKyc: employerProfileData.kycStatus,
      })
    ) {
      dispatch(appActions.setModalName(MODAL_NAME.KYC_INREVIEW_NOTIFY_MODAL))
      return
    }
    if (status === 'Active') {
      dispatch(appActions.setModalName(MODAL_NAME.QUESTION_MODAL))
      dispatch(jobActions.setModalId(id))
    }
    if (status !== 'Active') {
      changeJobStatus(id, status)
    }
  }

  const handleClickCari = () => {
    if (status !== 'Active') {
      dispatch(
        appActions.setModalName(MODAL_NAME.CANDIDATE_SEARCH_NOTICE_MODAL)
      )
    } else {
      trackEvents(JOB_SEARCH_CLICK_CARI_KANDIDAT, {
        entry_point: 'Loker',
      })

      setJobApplicantsPopupFilters({
        genders:
          requiredGender !== 'Both' && requiredGender != null
            ? requiredGender
            : undefined,
        homeAddresses: location?.shortAddress
          ? [
              {
                cityName: (location?.shortAddress as string) ?? undefined,
              },
            ]
          : undefined,
        minAge: minimumAge ? Number(minimumAge) : undefined,
        maxAge: maximumAge ? Number(maximumAge) : undefined,
        minSalary: minimumSalary ?? undefined,
        maxSalary: maximumSalary ?? undefined,
      })

      router.push({
        pathname: PATHS.SEARCH_CANDIDATE,
        query: {
          jid: id,
        },
      })
    }
  }

  const handleClickRepost = async () => {
    trackEvents(CLICK_ON_REPOST_JOB, eventProperties)
    if (repostJobPostPrice !== null) {
      const getCoinsBalanceResponse = await get(COINS_BALANCE_URL)
      trackEvents(COINS_DEDUCTION_ATTEMPT, {
        product: productCode,
      })
      const coinsBalanceData: { balance: number } = getCoinsBalanceResponse.data

      if (coinsBalanceData.balance < repostJobPostPrice) {
        dispatch(appActions.setModalName(MODAL_NAME.NOT_ENOUGH_COIN))
        setProductCode(productCode)
        trackEvents(COINS_DEDUCTION_INSUFFICIENT_BALANCE, {
          product: productCode,
        })
        trackEvents(COINS_DEDUCTION_FAILED, {
          product: productCode,
        })
        return
      }
      dispatch(jobActions.setModalId(id))
      dispatch(appActions.setModalName(MODAL_NAME.CHARGE_REPOST_JOB))
      return
    }

    dispatch(appActions.setModalName(MODAL_NAME.REPOST_MODAL))
    dispatch(jobActions.setModalId(id))
  }

  const handleClickBoost = () => {
    if (status !== 'Active') {
      dispatch(appActions.setModalName(MODAL_NAME.BOOST_NOTICE_MODAL))
      return
    }

    if (
      userKycInReview({
        employerKyc: employerProfileData?.kycStatus as KycVerificationStatus,
        companyKyc: employerProfileData?.company
          ?.kycStatus as KycVerificationStatus,
      })
    ) {
      dispatch(appActions.setModalName(MODAL_NAME.KYC_INREVIEW_NOTIFY_MODAL))
    } else if (
      userKycRejected({
        employerKyc: employerProfileData?.kycStatus as KycVerificationStatus,
        companyKyc: employerProfileData?.company
          ?.kycStatus as KycVerificationStatus,
      })
    ) {
      dispatch(appActions.setModalName(MODAL_NAME.KYC_REVERIFY_REJECTED_MODAL))
    } else {
      trackEvents(BOOST_CLICK_BOOST_BUTTON_JOB_SCREEN)
      router.push({
        pathname: '/boost',
        query: {
          jid: id,
        },
      })
    }
  }
  const isSwitchShown =
    status === 'Active' || status === 'InActive' || status === 'Pending'

  return (
    <div
      className={`mb-4 rounded-lg border border-[1px] border-neutral-gray-100 md:mb-0 overflow-auto ${
        status === 'Rejected'
          ? 'cursor-not-allowed bg-sidebar-gray'
          : 'cursor-pointer bg-white hover:shadow-elevation-16'
      } ${router?.query?.repost && index === 0 ? newPostHighlight : ''}`}
      role="button"
      tabIndex={0}
      aria-hidden="true"
    >
      <div
        className={cn(
          'flex justify-between bg-neutral-gray-25 rounded-t-md py-2 px-3 items-center',
          status === 'Expired' && 'bg-rejected-0'
        )}
      >
        <div className="flex">
          <Chip type={productPackage?.productName} />
          {isFree && <Chip type="Free" className="ml-2" />}
        </div>
        <div className="flex gap-x-2 items-center">
          <JobBadge status={status} />
          {isSwitchShown && (
            <Switch
              checked={status === 'Active'}
              onChange={handleJobStatusChange}
              className={`${status === 'Active' ? 'bg-[#52B788]' : 'bg-gray-200'} inline-flex h-[24px] w-[43.571px] flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 items-center ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                className={`${
                  status === 'Active' ? 'translate-x-6' : 'translate-x-0'
                } pointer-events-none inline-block h-[17.143px] w-[17.143px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
              />
            </Switch>
          )}
        </div>
      </div>
      <div className="flex flex-col justify-between h-[400px] p-4">
        <div>
          <button
            type="button"
            onClick={() => {
              jobsActions.setJobDetailDrawerState({
                open: true,
                jobId: id,
              })
            }}
            className="mb-4 w-full flex justify-between gap-x-4 text-left"
          >
            <div>
              <p
                className="line-clamp-2 h-fit flex-1 flex-[0.7] text-lg font-medium"
                title={title}
              >
                {title}
              </p>
              <p className="text-primary-gray py-1 text-sm">{companyName}</p>
              <div className="flex items-center text-primary-gray text-sm">
                <MapMarkerIcon className="mr-1" />
                {location?.shortAddress}
              </div>
            </div>
            <JobCardMenuToggle jobId={id} />
          </button>
          <JobCardMedia medias={medias} jobId={id} />
          <JobCardQuota
            totalQuota={productPackage?.passiveApplicantQuota}
            quotaUsed={metric?.passiveApplicantCount}
            jlType={productPackage?.productName}
            jobId={id}
            status={status}
          />
        </div>
        {expiryAt && (
          <p className="pt-2 text-sm text-primary-gray ">
            Kedaluwarsa pada {formatDateInID(expiryAt, 'dd MMM yyyy')}
          </p>
        )}
      </div>
      <JobCardFooter
        index={index}
        isBoosVisible={Boolean(
          initializeData && initializeData.accessControl.canBoost
        )}
        canRepost={canRepost}
        status={status}
        onClickCari={handleClickCari}
        onClickBoost={handleClickBoost}
        onClickRepost={handleClickRepost}
        disableBoostButton={!jobPostData.isBoostable}
      />
    </div>
  )
}

export default JobCard
