import cn from '@helpers/utils'
import { VariantProps, cva } from 'class-variance-authority'
import React from 'react'

const jobBadgeClassName = cva(['py-[2px] rounded-full'], {
  variants: {
    status: {
      Active: 'text-[#40916C]',
      Expired: 'text-red-700',
      InActive: 'text-primary-black',
      Pending: 'text-primary-black',
      Rejected: 'text-white',
    },
  },
})

type NoUndefinedField<T> = {
  [P in keyof T]-?: NoUndefinedField<NonNullable<T[P]>>
}

type JobBadgeProps = NoUndefinedField<VariantProps<typeof jobBadgeClassName>>

const JobBadge = (props: JobBadgeProps) => {
  const { status } = props
  const statusTextMap: Record<string, string> = {
    Active: 'Aktif',
    Expired: 'Kedaluwarsa',
    InActive: 'tidak aktif',
    Pending: 'Pending',
    Rejected: 'Ditolak',
  }

  const str = statusTextMap[status] || ''
  return (
    <div className={cn(jobBadgeClassName({ status }))}>
      <span>Loker {str}</span>
    </div>
  )
}

export default JobBadge
