import Image from 'next/image'
import React from 'react'

import Button from '@components/button'
import Modal from '@components/modal'

type Props = {
  open: boolean
  onClose: () => void
}

const KycFormSentModal: React.FC<Props> = ({ open, onClose }: Props) => (
  <Modal open={open} onClose={onClose} isCloseButtonHidden>
    <div className="flex w-[25rem] flex-col items-center">
      <div className="relative mb-4 h-[5rem] w-[3.5rem]">
        <Image
          src="/assets/icons/ic_kyc_document_checked.svg"
          alt="icon"
          fill
          sizes="100vw"
        />
      </div>
      <p className="mb-2 w-10/12 text-center text-xl font-semibold">
        Kami sedang meninjau informasi Anda.
      </p>
      <p className="mb-4 text-center">
        Tunggu kabar dari kami dalam 3x24 jam. Verifikasi Senin-Jumat
        09.00-17.00, kecuali hari libur.
      </p>
      <div className="w-full border">
        <Button
          appearance="primary"
          type="button"
          className="w-full"
          onClick={onClose}
        >
          Mengerti
        </Button>
      </div>
    </div>
  </Modal>
)

export default KycFormSentModal
