import Image from 'next/image'
import React from 'react'

import Button from '@components/button'
import Caption from '@components/typography/caption'
import H2 from '@components/typography/heading-two'

import Modal from '../modal'

type Props = {
  open: boolean
  onClose: () => void
  onSubmit: () => void
}

const OnboardModal = ({ open, onClose, onSubmit }: Props) => (
  <Modal open={open} onClose={onClose}>
    <div className="my-2 flex flex-col items-center text-center">
      <div className="mb-6">
        <Image
          alt="img_checked"
          src="/assets/images/img_checked.svg"
          width="57"
          height="80"
        />
      </div>
      <div className="mb-8 text-sm">
        <div className="mb-3">
          <H2>Selamat! Anda sudah siap untuk memulai</H2>
        </div>
        <div className="mx-auto max-w-sm">
          <Caption>
            Mulai dengan post pekerjaan pertama Anda, hanya perlu 5 menit dan
            GRATIS! Atma akan kirim notifikasi ke WhatsApp Anda ketika ada
            kandidat yang melamar.
          </Caption>
        </div>
      </div>
      <div className="flex w-full flex-col space-y-4">
        <Button type="button" appearance="primary" block onClick={onSubmit}>
          Mulai sekarang
        </Button>
      </div>
    </div>
  </Modal>
)

export default OnboardModal
