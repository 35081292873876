import React from 'react'
import useSWR from 'swr'
import useSWRImmutable from 'swr/immutable'

import JobListingFilterTabsButton from '@features/applications/components/job-listing-filter/JobListingFilterTabsButton'
import { useJobsActions } from '@features/jobs/job-detail/reducers'
import { GetEmployerProfileAPIResponseData } from '@features/kyc/types'

import { useAppSelector } from '@hooks/redux'

import { EMPLOYERS_PROFILE_URL, JOB_POST_SUMMARY } from '@constants/url'

import { JobListingFilterTab } from '@custom-types/components/job-listing-filter'

const JobListingStatusFilter = () => {
  const currentTab = useAppSelector(
    (state) => state.jobReducer.jobListingQueries.tab
  )

  const jobsActions = useJobsActions()

  const { data: employerProfileData } =
    useSWRImmutable<GetEmployerProfileAPIResponseData>(EMPLOYERS_PROFILE_URL)
  const companyId = employerProfileData?.company?.id || ''

  const {
    data: jobCount = {
      activeJobsCount: 0,
      draftJobsCount: 0,
      expiredJobsCount: 0,
      inactiveJobsCount: 0,
      pendingJobsCount: 0,
    },
  } = useSWR<{
    activeJobsCount: number
    inactiveJobsCount: number
    draftJobsCount: number
    pendingJobsCount: number
    expiredJobsCount: number
  }>(
    companyId
      ? JOB_POST_SUMMARY(String(employerProfileData?.company?.id))
      : null
  )

  const onClickTab = (tab: JobListingFilterTab) => {
    jobsActions.setJobsStatusTab(tab)
    jobsActions.setJobListingQueries({ searchText: undefined })
    const input = document.getElementById('job_listing_search_input')
    if (input) {
      ;(input as HTMLInputElement).value = ''
    }
  }

  return (
    <div className="flex items-center gap-[8px] [&>*]:shrink-0 overflow-auto">
      <JobListingFilterTabsButton
        totalRecord={Object.keys(jobCount).reduce((acc, key) => {
          acc += jobCount[key as keyof typeof jobCount]
          return acc
        }, 0)}
        buttonTitle="Semua"
        currentTab={currentTab}
        onClickTab={onClickTab}
        tab="all"
      />
      <JobListingFilterTabsButton
        totalRecord={jobCount.activeJobsCount}
        buttonTitle="Aktif"
        currentTab={currentTab}
        onClickTab={onClickTab}
        tab="active"
      />
      <JobListingFilterTabsButton
        totalRecord={jobCount.inactiveJobsCount}
        buttonTitle="Tidak aktif"
        currentTab={currentTab}
        onClickTab={onClickTab}
        tab="inactive"
      />
      <JobListingFilterTabsButton
        totalRecord={jobCount.expiredJobsCount}
        buttonTitle="Kedaluwarsa"
        currentTab={currentTab}
        onClickTab={onClickTab}
        tab="expired"
      />
    </div>
  )
}

export default JobListingStatusFilter
