import classNames from 'classnames'
import React from 'react'

import { JobListingFilterTab } from '@custom-types/components/job-listing-filter'

const JobListingFilterTabsButton = ({
  currentTab,
  buttonTitle,
  tab,
  onClickTab,
  totalRecord,
}: {
  tab: JobListingFilterTab
  buttonTitle: React.ReactNode
  currentTab: JobListingFilterTab
  onClickTab: (tab: JobListingFilterTab) => void
  totalRecord: number
}) => {
  const selected = currentTab === tab

  return (
    <button
      onClick={() => onClickTab(tab)}
      type="button"
      className={classNames(
        'px-[12px]',
        'py-[8px]',
        'rounded-md',
        'flex',
        'gap-x-[8px]',
        selected && 'bg-indigo-50',
        selected && 'text-primary-blue',
        selected && 'font-semibold'
      )}
    >
      {buttonTitle}
      <span
        className={classNames(
          'rounded-full',
          'border-[1px]',
          'border-primary-blue',
          'w-[24px]',
          'h-[24px]',
          'text-[12px]',
          'flex',
          'items-center',
          'justify-center',
          selected ? 'text-white' : 'text-primary-blue',
          selected && 'bg-primary-blue',
          selected && 'text-white'
        )}
      >
        {totalRecord}
      </span>
    </button>
  )
}

export default JobListingFilterTabsButton
